import React from 'react';
import { YourSuccessLogoImg, BannerPersonImg } from '@constants/images';
import LoginForm from './LoginForm';

const Banner = () => {
  return (
    <div className="flex flex-col lg:flex-row items-center justify-between mt-4 mb-6 lg:mt-8">
      <div className="bg-white rounded-tr-3xl rounded-br-3xl flex flex-col items-center justify-between w-full lg:w-2/3">
        <div className="flex flex-col items-center w-full lg:items-center lg:text-center">
          <img
            src={YourSuccessLogoImg}
            alt="YourSuccess Logo"
            className="w-[180px] h-[80px] mb-6 lg:mb-4 lg:w-[227px] lg:h-[100px]"
          />
          <h1 className="text-2xl font-bold mb-6 text-center mx-[10px] sm:mx-2.5">
            Let your bank account help you in your Chapter 13 bankruptcy
          </h1>
        </div>
        <div className="block lg:hidden mb-6">
          <img src={BannerPersonImg} alt="Banner Person" className="w-full h-[150px]" />
        </div>
        <div className="w-full px-4 lg:px-0 lg:flex lg:justify-center">
          <LoginForm />
        </div>
      </div>
      {/* Right Side (Image on Desktop) */}
      <div className="hidden relative top-10 lg:flex w-full lg:w-1/3 justify-center lg:justify-end lg:mb-0">
        <img src={BannerPersonImg} alt="Banner Person" className="w-full h-auto" />
      </div>
    </div>
  );
};

export default Banner;
