import React from 'react';
import { DebitExplainCollageImg, MastercardBlackImg } from '@constants/images';

const DebitExplain = ({ cashBackRef, billTrackingRef, debitFeaturesRef }) => {
  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div
      className="relative w-full p-8 bg-cover bg-center flex justify-center items-center mx-auto"
      style={{
        backgroundImage: `url(${MastercardBlackImg})`
      }}>
      <div className="max-w-6xl mx-auto bg-white bg-opacity-50 backdrop-blur-sm rounded-lg shadow-lg p-8">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="images w-full md:w-1/2 flex justify-center items-center gap-5 mb-8 md:mb-0">
            <div className="flex flex-col justify-center items-center gap-5">
              <img
                src={DebitExplainCollageImg}
                alt="Photo 1"
                className="rounded-lg w-full h-auto max-w-xs md:max-w-sm"
              />
            </div>
          </div>
          <div className="w-full md:w-1/2 md:ml-8 flex flex-col justify-center">
            <h1 className="text-3xl md:text-4xl font-bold text-gray-800 leading-tight mb-6">
              Unlock financial support and empowerment
            </h1>

            <ul className="space-y-6 text-lg text-gray-700">
              <li className="flex items-center">
                <i className="fas fa-check-circle text-green-500 mr-4"></i>
                <div>
                  <span>
                    Earn cash-back rewards* when using your YourSuccess Debit Mastercard®. Your
                    everyday spending results in extra money that you can use to help make your trustee payment.
                  </span>
                  <br />
                  <button
                    onClick={() => scrollToSection(cashBackRef)}
                    className="text-blue-500 underline">
                    Learn More
                  </button>
                </div>
              </li>
              <li className="flex items-center">
                <i className="fas fa-check-circle text-green-500 mr-4"></i>
                <span>
                  Pay your most important bills like your mortgage or car payment and let your
                  YourSuccess Bank Account automate a payment history you can access on demand.
                  <br />
                  <button
                    onClick={() => scrollToSection(billTrackingRef)}
                    className="text-blue-500 underline">
                    Learn More
                  </button>
                </span>
              </li>
              <li className="flex items-center">
                <i className="fas fa-check-circle text-green-500 mr-4"></i>
                <span>
                  Take control of your payment plan with early access to your hard-earned pay.
                  <br />
                  <button
                    onClick={() => scrollToSection(debitFeaturesRef)}
                    className="text-blue-500 underline">
                    Learn More
                  </button>
                </span>
              </li>
            </ul>
            <a
              href="https://tfsbillpay.com/tfs_banking/signup"
              target="_blank"
              rel="noreferrer noopener">
              <input
                type="button"
                value={'Register Now'}
                className="mt-4 bg-greyblue hover:bg-greyblueHover text-white font-semibold rounded-full transition duration-300 tablet:w-[315px] desktop:w-[275px] h-14 cursor-pointer disabled:cursor-not-allowed disabled:opacity-50"
              />
            </a>
          </div>
        </div>
        <div className="italic text-xs mt-7 text-center">
          *This optional Cash-Back Rewards program is not a Pathward product or service, nor is it
          endorsed or supported by Pathward in any way.
        </div>
      </div>
    </div>
  );
};

export default DebitExplain;
