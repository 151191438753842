import React from 'react';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import BudgetingBuddyComponent from '@components/shared/BudgetingBuddy';

const SetNextMonthsGoalsButton = ({ month = DateTime.now() }) => {
  const currentDate = DateTime.now();
  const nextMonthDate = currentDate.plus({ months: 1 }).startOf('month');
  const nextMonthName = nextMonthDate.toLocaleString({ month: 'long' });
  const daysUntilNextMonth = nextMonthDate.diff(currentDate, 'days').days;
  const currentMonthsName = month.toLocaleString({ month: 'long' });

  return (
    <div className="flex w-full justify-between items-center mb-16">
      {daysUntilNextMonth > 15 ? (
        <span>{currentMonthsName}'s Expense Tracker</span>
      ) : (
        <Link
          to="/dashboard/tracker/monthly-goals"
          state={{ month: nextMonthDate.toJSDate() }}
          className="px-2 py-1 text-white bg-blue-500 rounded hover:bg-blue-600">
          Set {nextMonthName}'s Goals
        </Link>
      )}
      <BudgetingBuddyComponent
        copy="Need a quick tour? Click here to watch the video!"
        videoId="rgeisi0JddI"
        imageWidth={100}
      />
    </div>
  );
};

export default SetNextMonthsGoalsButton;
