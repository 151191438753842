import React from 'react';
import { CardAndAppImg, WomenShoppingImg } from '@constants/images';

const Features = () => {
  return (
    <div
      className="bg-cover bg-center py-8 px-4 lg:px-16"
      style={{
        backgroundImage: `url(${WomenShoppingImg})`
      }}>
      <div className="max-w-7xl mx-auto bg-white bg-opacity-80 rounded-lg shadow-lg overflow-hidden md:flex">
        <div className="md:w-1/2 p-8">
          <img src={CardAndAppImg} alt="Photo 1" className="w-full h-auto" />
        </div>
        <div className="md:w-1/2 p-8 flex flex-col justify-center">
          <h1 className="text-3xl font-bold mb-4">About the YourSuccess Bank Account</h1>
          <ul className="mb-6 space-y-4">
            <li className="flex items-start">
              <span className="text-green-500 mr-2">✔</span>
              <p>There is no credit report pulled as part of the registration process.</p>
            </li>
            <li className="flex items-start">
              <span className="text-green-500 mr-2">✔</span>
              <p>
                The YourSuccess Bank Account is an exclusive benefit available only to TFS users.
              </p>
            </li>
            <li className="flex items-start">
              <span className="text-green-500 mr-2">✔</span>
              <p>
                The YourSuccess Bank Account provided by Pathward, N.A., is a digital deposit
                account. You can direct funds to the account just like any deposit account.
              </p>
            </li>
          </ul>
          <p className="mb-8">
            Your journey to a Chapter 13 bankruptcy discharge is a path toward renewed financial
            well-being. TFS is committed to help you along this journey.
          </p>
          <a href="https://tfsbillpay.com/tfs_banking/signup" target="_blank" rel="noreferrer noopener">
            <input
              type="button"
              value={'Register Now'}
              className="mt-4 bg-greyblue hover:bg-greyblueHover text-white font-semibold rounded-full transition duration-300 tablet:w-[315px] desktop:w-[275px] h-14 cursor-pointer disabled:cursor-not-allowed disabled:opacity-50"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Features;
